import {
  AutocompleteSource,
  AutocompleteState,
  BaseItem,
  createAutocomplete,
} from '@algolia/autocomplete-core';
import {
  createTagsPlugin,
  Tag as ATag,
} from '@algolia/autocomplete-plugin-tags';
import {
  getAlgoliaFacets,
  getAlgoliaResults,
} from '@algolia/autocomplete-preset-algolia';
import {
  Box,
  createIcon,
  Divider,
  Flex,
  Heading,
  HStack,
  Image,
  List,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  useColorMode,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import algoliasearch from 'algoliasearch/lite';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React, { SyntheticEvent, useCallback, useRef } from 'react';
import colorModeValue from 'src/utils/colorModeValue';
import { debouncePromise } from 'src/utils/debouncePromise';
import { Highlight } from './Highlight';
import SearchButton from '../SearchButton';
import { Snippet } from './Snippet';

const searchClient = algoliasearch(
  `${process.env.GATSBY_ALGOLIA_APP_ID}`,
  `${process.env.GATSBY_ALGOLIA_READ_KEY}`
);

interface Post extends BaseItem {
  objectID: string;
  title: string;
  parent: {
    rawBody: string;
  };
  image: {
    publicURL: string;
  }
  slug: string;
}

interface Tag extends BaseItem {
  label: string;
  facet: string;
}


function mapToAlgoliaFilters<TTag extends ATag>(tagsByFacet: {[key: string]: TTag[]}, operator: string = 'AND') {
  return Object.keys(tagsByFacet)
    .map((facet) => {
      return `(${tagsByFacet[facet]
        .map(({ label }) => `${facet}:"${label}"`)
        .join(' OR ')})`;
    })
    .join(` ${operator} `);
}

function groupBy<TTag>(items: TTag[], predicate: (item: TTag) => string) {
  return items.reduce((acc, item) => {
    const key = predicate(item);

    if (!acc.hasOwnProperty(key)) {
      acc[key] = [];
    }

    acc[key].push(item);

    return acc;
  }, {});
}

export interface PureAutoCompleteSearchProps {
  siteUrl: string;
}

export function PureAutoCompleteSearch({ siteUrl }: PureAutoCompleteSearchProps) {
  // (1) Create a React state.
  const [autocompleteState, setAutocompleteState] = React.useState<AutocompleteState<Post | Tag>>({
    activeItemId: null,
    query: '',
    completion: null,
    collections: [],
    isOpen: false,
    status: 'idle',
    context: {},
  });

  const debounced = debouncePromise(() => {
    return [
      {
        sourceId: 'tags',
        getItems({ query }) {
          return getAlgoliaFacets({
            searchClient,
            queries: [
              {
                indexName: `${process.env.GATSBY_ALGOLIA_INDEX_NAME}`,
                facet: 'tags',
                params: {
                  facetQuery: query,
                  maxFacetHits: 5,
                },
              },
            ],
            transformResponse({ facetHits }) {
              return facetHits[0].map(hit => ({ ...hit, facet: 'tags' }));
            },
          });
        },
      },
      {
        sourceId: 'posts',
        getItemInputValue({ item }) {
          return (item as Post).query as string;
        },
        getItems({ query, state }) {
          if (!query) {
            return [];
          }

          const tagsByFacet = state.context.tagsPlugin
            ? groupBy<Tag>(
                (state.context.tagsPlugin as { tags: Tag[] }).tags,
                tag => tag.facet,
              )
            : {};

          return getAlgoliaResults<Post>({
            searchClient,
            queries: [
              {
                indexName: `${process.env.GATSBY_ALGOLIA_INDEX_NAME}`,
                query,
                params: {
                  hitsPerPage: 5,
                  filters: mapToAlgoliaFilters(tagsByFacet),
                  attributesToSnippet: ['parent.rawBody:20'],
                },
              },
            ],
          });
        },
        getItemUrl({ item }) {
          return siteUrl + (item as Post).slug;
        },
        onSelect({ item }) {
          nav((item as Post).slug);
        },
      },
    ] as AutocompleteSource<Post | Tag>[];
  }, 500);

  const autocomplete = React.useMemo(
    () => {
      const tagsPlugin = createTagsPlugin<Tag>({
        getTagsSubscribers() {
          return [
            {
              sourceId: 'tags',
              getTag({ item }) {
                return item;
              },
            },
          ];
        },
      });

      return createAutocomplete<Post | Tag, SyntheticEvent, SyntheticEvent, SyntheticEvent>({
        stallThreshold: 500,
        openOnFocus: true,
        onStateChange({ state }) {
          // (2) Synchronize the Autocomplete state with the React state.
          setAutocompleteState(state);
        },
        getSources() {
          return debounced();
        },
        navigator: {
          navigate({ itemUrl }) {
            if (itemUrl) nav(itemUrl);
          },
        },
        plugins: [tagsPlugin],
      });
    },
    []
  );

  const inputRef = useRef<HTMLInputElement>(null);
  const nav = useCallback((url: string) => {
    navigate(url);
    inputRef.current?.blur();
  }, []);

  const { colorMode } = useColorMode();

  return (
    <div className="aa-Autocomplete" {...autocomplete.getRootProps({})}>
      <Popover
        returnFocusOnClose={false}
        isOpen={autocompleteState.isOpen}
        closeOnBlur={false}
        autoFocus={false}
        arrowShadowColor={useColorModeValue('now.500', 'now.200')}
        placement="bottom-start"
      >
        <PopoverTrigger>
          <form className="aa-form" {...autocomplete.getFormProps({ inputElement: inputRef.current })}>
            <SearchButton
              autoCompleteInputProps={autocomplete.getInputProps({
                inputElement: null,
              })}
              isSearching={autocompleteState.status === "loading"}
              inputRef={inputRef}
              onFocus={() => {
              }}
              onBlur={() => {
                // autocomplete.setQuery('');
              }}
            />
          </form>
        </PopoverTrigger>
        <PopoverContent
          layerStyle="now"
          borderColor="transparent"
          boxShadow="primary"
          w={["100vw", "lg"]}
        >
          <PopoverArrow bg={colorModeValue(colorMode, 'now.500', 'now.200')} />
          <PopoverBody px="0">
            {(autocompleteState.context.tagsPlugin as {tags: ATag[]})?.tags.length > 0 &&
              <HStack p={3}>
                {((autocompleteState.context.tagsPlugin as {tags: ATag[]}).tags).map(tag =>
                  <Tag
                    key={tag.label}
                    size="lg"
                    borderRadius="full"
                    variant="solid"
                    colorScheme={colorModeValue(colorMode, 'whiteAlpha', 'blackAlpha')}
                  >
                    <TagLabel>{tag.label}</TagLabel>
                    <TagCloseButton
                      onMouseDown={event => event.preventDefault()}
                      onClick={() => {
                        tag.remove();
                      }} 
                    />
                  </Tag>
                )}
              </HStack>
            }
            {autocompleteState.collections.map((collection, index) => {
              const { source, items } = collection;
              if (source.sourceId === 'tags') {
                return (
                  <Box key={`source-${index}`}>
                    {items.length > 0 && (
                    <>
                      <HStack p={2}>
                        <Heading size="sm">标签</Heading>
                        <Divider flex="1" />
                      </HStack>
                      <List {...autocomplete.getListProps()}>
                        {(items as Tag[])
                          .filter(tag =>
                            !(autocompleteState.context.tagsPlugin as { tags: ATag[] }).tags.find(it => it.label === tag.label),
                          )
                          .map(item => (
                            <ListItem
                              className="aa-Item"
                              key={item.label}
                              sx={{
                                '&[aria-selected=true]': {
                                  backgroundColor: colorModeValue(
                                    colorMode,
                                    'whiteAlpha.400',
                                    'blackAlpha.200',
                                  ),
                                  '&:hover svg': {
                                    display: 'inline',
                                  }
                                },
                              }}
                              px={3}
                              py={2}
                              cursor="pointer"
                              {...autocomplete.getItemProps({
                                item,
                                source,
                              })}
                            >
                              <Flex alignItems="center">
                                <Text flex="1">{item.label}</Text>
                                <FilterIcon icon="white" boxSize={5} justifySelf="end" display="none"/>
                              </Flex>
                            </ListItem>
                          ))}
                      </List>
                    </>
                    )}
                  </Box>
                );
              }

              if (source.sourceId === 'posts') {
                return (
                  <Box key={`source-${index}`}>
                    {items.length > 0 && (
                    <>
                      <HStack p={2}>
                        <Heading size="sm">文章</Heading>
                        <Divider flex="1" />
                      </HStack>
                      <List {...autocomplete.getListProps()} >
                        {(items as Post[]).map(item => (
                          <ListItem
                            className="aa-Item"
                            key={item.objectID}
                            sx={{
                              '&[aria-selected=true]': {
                                backgroundColor: colorModeValue(colorMode, 'whiteAlpha.400', 'blackAlpha.200'),
                              },
                            }}
                            p={3}
                            cursor="pointer"
                            {...autocomplete.getItemProps({
                              item,
                              source,
                            })}
                          >
                            <HStack spacing={3} alignItems="start">
                              {item.image ? (
                                <Image
                                  objectFit="cover"
                                  boxSize={24}
                                  borderRadius="md"
                                  src={item.image.publicURL}
                                  alt="cover"
                                />
                              ) : (
                                <Box
                                  boxSize={24}
                                  borderRadius="md"
                                  overflow="hidden"
                                >
                                  <StaticImage
                                    src="../../assets/images/no-image.jpeg"
                                    alt="cover"
                                    width={100}
                                    height={100}
                                  />
                                </Box>
                              )}
                              <VStack flex="1" alignItems="start">
                                <Heading fontSize="md">
                                  <Highlight hit={item} attribute="title" tag={HighlightRender} />
                                </Heading>
                                <Text>
                                  <Snippet hit={item} attribute={["parent", "rawBody"]} tag={HighlightRender} />
                                </Text>
                              </VStack>
                            </HStack>
                          </ListItem>
                        ))}
                      </List>
                    </>
                    )}
                  </Box>
                );
              }
            })}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </div>
  );
}

export default function AutoCompleteSearch() {
  const site = useStaticQuery<GatsbyTypes.SiteQuery>(
    graphql`
      query Site {
        site {
          siteMetadata {
            title
            siteUrl
            social {
              name
              url
            }
          }
        }
      }
    `,
  );
  
  return <PureAutoCompleteSearch siteUrl={site!.site!.siteMetadata!.siteUrl!}/>
}

const FilterIcon = createIcon({
  displayName: 'FilterIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
    />
  ),
});


const HighlightRender: React.FC = ({children}) => <Text as="em" color="yellow">{children}</Text>
