import { ComponentSingleStyleConfig } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

type Dict = Record<string, any>;

const baseStyle = {
  borderRadius: 'primary',
  fontWeight: 'normal',
};

function variantNow(props: Dict) {
  const { colorScheme: c } = props;

  const {
    bg = `${c}.500`,
    color = 'white',
    hoverBg = `${c}.400`,
  } = {};

  const background = mode(bg, `${c}.200`)(props);

  return {
    bg: background,
    color: mode(color, `gray.800`)(props),
    _hover: {
      bg: mode(hoverBg, `${c}.300`)(props),
      boxShadow: 'md',
      _disabled: {
        bg: background,
      },
    },
    _active: {
      boxShadow: 'initial',
    },
  };
}

function variantIcon(props: Dict) {
  const { colorScheme: c } = props;

  return {
    display: 'flex',
    height: 'auto',
    lineHeight: "inherit",
    m: 0,
    p: 0,
    color: mode(`${c}.500`, `${c}.200`)(props),
    _hover: {
      color: mode(`${c}.200`, `${c}.400`)(props),
    },
  };
}

const variants = {
  now: variantNow,
  icon: variantIcon,
  outline: {
    borderRadius: 'full',
  },
};

const sizes = {
  md: {
    px: 6,
    fontSize: 'xs',
  },
  lg: {
    px: 8,
    fontSize: 'md',
  },
};

const defaultProps = {
  variant: 'now',
};

export default {
  baseStyle,
  sizes,
  variants,
  defaultProps,
} as ComponentSingleStyleConfig;
