import React from 'react';
import {
  IconButton,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  useTheme,
  ColorProps,
  useColorModeValue,
} from '@chakra-ui/react';
import { useFontSize } from '.';

export interface FontSizeControlProps extends Pick<ColorProps, 'color'> {}

const FontSizeControl = ({ color }: FontSizeControlProps) => {
  const theme = useTheme();
  const { fontSize, setFontSize } = useFontSize();

  return (
    <Popover>
      <PopoverTrigger>
        <IconButton
          aria-label="Font Size"
          variant="unstyled"
          color={color}
          icon={
            <Text
              as="span"
              className="iconfont icon-font-size"
              fontSize="2xl"
            />
          }
        />
      </PopoverTrigger>
      <PopoverContent width="300px" borderRadius="6px">
        <PopoverBody display="flex" alignItems="center" px="12px" py="8px" color={useColorModeValue('gray.900', 'whiteAlpha.900')}>
          <Text fontSize="12px">A</Text>
          <Slider
            value={fontSize}
            min={12}
            max={24}
            step={2}
            mx="16px"
            onChange={setFontSize}
          >
            <SliderTrack bg="now.200">
              <SliderFilledTrack bg="now.500" />
            </SliderTrack>
            <SliderThumb
              boxSize="24px"
              boxShadow={`${theme.shadows.base} !important`}
            />
          </Slider>
          <Text fontSize="24px">A</Text>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default FontSizeControl;
