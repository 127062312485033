import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Helmet from 'react-helmet';
import siteImage from '../assets/images/logo.png';

interface Props {
  title?: string;
  description?: string;
  url?: string;
  pathname?: string;
}

/**
 * This react helmt code is adapted from
 * https://themeteorchef.com/tutorials/reusable-seo-with-react-helmet.
 *
 * A great tutorial explaining how to setup a robust version of an
 * SEO friendly react-helmet instance.
 *
 *
 * Use the Helmt on pages to generate SEO and meta content!
 *
 * Usage:
 * <SEO
 *   title={title}
 *   description={description}
 *   image={image}
 * />
 *
 */
const SEO = ({ title, description, url, pathname }: Props) => {
  const results = useStaticQuery<GatsbyTypes.SeoQuery>(
    graphql`
      query Seo {
        site {
          siteMetadata {
            title
            name
            siteUrl
            description
            social {
              name
              url
            }
          }
        }
      }
    `,
  );
  const site = results.site?.siteMetadata;
  const twitter =
    site?.social?.find(option => option?.name === 'twitter') || {};

  const fullURL = (path: string) => {
    if (path && path.startsWith('data')) {
      return path;
    }
    if (path && site) {
      return `${site.siteUrl}${path}`;
    }
    return site?.siteUrl || '';
  };

  // const pageTitle = title ? `${title} | ${site.title}` : site.title;
  const pageTitle = title || site?.title || 'default title';
  const pageDescription = description || site?.description;

  return (
    <Helmet title={pageTitle} htmlAttributes={{ lang: 'zhCN' }}>
      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edege" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link rel="canonical" href={fullURL(pathname)} />
      {pageDescription && <meta name="description" content={pageDescription} />}
      {pageTitle && <meta itemProp="name" content={pageTitle} />}
      {pageDescription && (
        <meta itemProp="description" content={pageDescription} />
      )}
      <meta itemProp="image" content={fullURL(siteImage)} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={pageTitle} />
      {site?.name && <meta name="twitter:site" content={site.name} />}
      {pageDescription && (
        <meta name="twitter:description" content={pageDescription} />
      )}
      {twitter.url && <meta name="twitter:creator" content={twitter.url} />}
      <meta name="twitter:image" content={fullURL(siteImage)} />
      <meta property="og:title" content={pageTitle} />
      <meta property="og:url" content={url} />
      {siteImage && <meta property="og:image" content={fullURL(siteImage)} />}
      {pageDescription && (
        <meta property="og:description" content={pageDescription} />
      )}
      {site?.name && <meta property="og:site_name" content={site.name} />}
      <link
        href="https://fonts.googleapis.com/css?family=Montserrat:400,700,200"
        rel="stylesheet"
        type="text/css"
      />
    </Helmet>
  );
};

export default SEO;
