import Button from './button';
import Menu from './menu';
import Link from './link';
import Input from './input';

export default {
  Button,
  Menu,
  Link,
  Input,
};
