import { mode } from '@chakra-ui/theme-tools';

function nowStyleList(props: Record<string, any>) {
  const { colorScheme: c } = props;
  return {
    bg: mode(`${c}.500`, `${c}.200`)(props),
    boxShadow: mode(`primary`, `dark-lg`)(props),
    color: mode('whiteAlpha.900', 'blackAlpha.700')(props),
    minW: '72',
    borderRadius: 'primary',
    borderWidth: 0,
  };
}

function nowStyleItem(props: Record<string, any>) {
  return {
    py: 4,
    px: 5,
    fontSize: 'md',
    transition: 'background 50ms ease-in 0s',
    _focus: {
      bg: mode('whiteAlpha.400', 'blackAlpha.200')(props),
    },
    _active: {
      bg: mode('whiteAlpha.300', 'blackAlpha.100')(props),
    },
    _expanded: {
      bg: mode('whiteAlpha.400', 'blackAlpha.200')(props),
    },
    _disabled: {
      opacity: 0.4,
      cursor: 'not-allowed',
    },
  };
}

function variantNow(props: Record<string, any>) {
  return {
    list: nowStyleList(props),
    item: nowStyleItem(props),
  };
}

const variants = {
  now: variantNow,
};

export default {
  variants,
};
