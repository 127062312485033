import { HamburgerIcon, MoonIcon, SunIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  Grid,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Text,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';
import { graphql, Link as GatsbyLink, navigate, useStaticQuery } from 'gatsby';
import React from 'react';
import Logo from '../assets/images/logo.png';
import { PureAutoCompleteSearch}from './AutoCompleteSearch';
import FontSizeControl from './FontSizeProvider/FontSizeControl';

export interface PureHeaderProps {
  categories: GatsbyTypes.HeaderQuery['allCategoryJson'];
  siteUrl: string;
}

export function PureHeader({
  categories,
  siteUrl
}: PureHeaderProps) {
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Grid
      templateColumns="3fr 1fr 3fr"
      gap={4}
      px={4}
      layerStyle="now"
      alignItems="center"
      h={14}
    >
      <Flex alignItems="center">
        <Menu variant="now">
          <MenuButton
            as={IconButton}
            icon={<HamburgerIcon />}
            size="md"
            fontSize="2xl"
            variant="unstyled"
            display="inline-flex"
            colorScheme="whiteAlpha"
          >
            Actions
          </MenuButton>
          <Portal>
            <MenuList
              sx={{
                _before: {
                  display: 'inline-block',
                  position: 'absolute',
                  width: 0,
                  height: 0,
                  verticalAlign: 'middle',
                  top: '-.4em',
                  left: '1em',
                  borderLeft: '.4em solid transparent',
                  borderRight: '.4em solid transparent',
                  borderBottom: '.4em solid',
                  content: '""',
                  color: useColorModeValue('now.500', 'now.200'),
                },
              }}
            >
              {categories.nodes.map(category => (
                <MenuItem
                  key={category.code}
                  icon={
                    <Text
                      as="span"
                      className={`iconfont ${category.icon}`}
                      fontSize="md"
                    />
                  }
                  onClick={() => {
                    navigate(`/${category.code}`);
                  }}
                >
                  {category.name}
                </MenuItem>
              ))}
              <MenuItem
                icon={
                  <Text
                    as="span"
                    className="iconfont icon-archive"
                    fontSize="md"
                  />
                }
                onClick={() => {
                  navigate('/archive');
                }}
              >
                归档
              </MenuItem>
              <MenuItem
                icon={
                  <Text
                    as="span"
                    className="iconfont icon-profile"
                    fontSize="md"
                  />
                }
                onClick={() => {
                  navigate('/about');
                }}
              >
                关于
              </MenuItem>
            </MenuList>
          </Portal>
        </Menu>
        <Text as="span">频道</Text>
      </Flex>
      <Flex justifyContent="center">
        <GatsbyLink to="/">
          <Image boxSize="30px" src={Logo} alt="logo" />
        </GatsbyLink>
      </Flex>
      <Flex overflow="hidden" alignItems="center" justifyContent="flex-end">
        <Box flex="1">
          <PureAutoCompleteSearch siteUrl={siteUrl}/>
        </Box>
        <FontSizeControl />
        <IconButton
          aria-label="Color Mode"
          variant="unstyled"
          size="lg"
          icon={colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
          onClick={toggleColorMode}
        />
      </Flex>
    </Grid>
  );
}
export default function Header() {
  const headerQuery = useStaticQuery<GatsbyTypes.HeaderQuery>(
    graphql`
      query Header {
        site {
          siteMetadata {
            siteUrl
          }
        }
        allCategoryJson {
          nodes {
            id
            code
            name
            icon
          }
        }
      }
    `,
  );

  return <PureHeader categories={headerQuery.allCategoryJson} siteUrl={headerQuery.site!.siteMetadata!.siteUrl!}/>;
}

