exports.components = {
  "component---src-components-posts-tsx": () => import("./../../../src/components/Posts.tsx" /* webpackChunkName: "component---src-components-posts-tsx" */),
  "component---src-gatsby-theme-blog-core-templates-post-query-ts": () => import("./../../../src/gatsby-theme-blog-core/templates/post-query.ts" /* webpackChunkName: "component---src-gatsby-theme-blog-core-templates-post-query-ts" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-coder-mdx": () => import("./../../../src/pages/about/coder.mdx" /* webpackChunkName: "component---src-pages-about-coder-mdx" */),
  "component---src-pages-about-index-mdx": () => import("./../../../src/pages/about/index.mdx" /* webpackChunkName: "component---src-pages-about-index-mdx" */),
  "component---src-pages-about-otaku-mdx": () => import("./../../../src/pages/about/otaku.mdx" /* webpackChunkName: "component---src-pages-about-otaku-mdx" */),
  "component---src-pages-archive-tsx": () => import("./../../../src/pages/archive.tsx" /* webpackChunkName: "component---src-pages-archive-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

