const layerStyles = {
  center: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
  now: {
    color: 'whiteAlpha.900',
    bgColor: 'now.500',
    '.chakra-ui-dark &': {
      color: 'blackAlpha.700',
      bgColor: 'now.200',
    },
  },
  nowContent: {
    color: 'whiteAlpha.900',
    '.chakra-ui-dark &': {
      color: 'blackAlpha.700',
    },
  },
  nowText: {
    color: 'now.500',
    '.chakra-ui-dark &': {
      color: 'now.200',
    },
  },
};

export default layerStyles;
