import { mode, Styles } from "@chakra-ui/theme-tools";

const styles: Styles = {
  global: (props) => ({
    '.js-focus-visible :focus:not([data-focus-visible-added])': {
      outline: 'none',
      boxShadow: 'none',
    },
    '*, *::before, &::after': {
      borderColor: mode('gray.200', 'whiteAlpha.800')(props),
    }
  }),
};

export default styles;
