import { __DEV__ } from '@chakra-ui/utils';

const hasSupport = () => typeof Storage !== 'undefined';

export interface StorageManager {
  get(key: string, init?: any): any;
  set(key: string, value: any): void;
  type: 'cookie' | 'localStorage';
}

export const localStorageManager: StorageManager = {
  get(key, init?) {
    if (!hasSupport()) return init;
    try {
      const value = localStorage.getItem(key);
      return value ?? init;
    } catch (error) {
      if (__DEV__) {
        console.log(error);
      }
      return init;
    }
  },
  set(key, value) {
    if (!hasSupport()) return;
    try {
      localStorage.setItem(key, value);
    } catch (error) {
      if (__DEV__) {
        console.log(error);
      }
    }
  },
  type: 'localStorage',
};
