/* eslint-disable react/jsx-props-no-spreading */
import { Box, useTheme } from '@chakra-ui/react';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Footer from './Footer';
import Header from './Header';
import SEO from './SEO';
import {FontSizeProvider} from './FontSizeProvider';

export interface LayoutProps {
  children: React.ReactChild;
}

export interface PureLayoutProps extends LayoutProps {
  layout: GatsbyTypes.LayoutQuery;
}

export function PureLayout({ layout, children }: PureLayoutProps) {
  const theme = useTheme();
  return (
    <>
      <FontSizeProvider>
        <Header />
        <SEO />
        <Box as="main" minH={['auto', `calc(100vh - ${theme.sizes['14']})`]}>
          {children}
        </Box>
        <Footer
          avatar={layout.file?.childImageSharp?.fixed?.src}
          name={layout.site?.siteMetadata?.name}
          copyright={layout.site?.siteMetadata?.copyright}
          socials={layout.site?.siteMetadata?.social}
        />
      </FontSizeProvider>
    </>
  );
}

export default function Layout(props: LayoutProps) {
  const queryResults = useStaticQuery<GatsbyTypes.LayoutQuery>(
    graphql`
      query Layout {
        file(relativePath: { eq: "avatar.png" }) {
          childImageSharp {
            fixed(width: 125, height: 125) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        site {
          siteMetadata {
            name
            copyright
            social {
              name
              url
            }
          }
        }
      }
    `,
  );

  return <PureLayout {...props} layout={queryResults} />;
}
