import {
  ThemeConfig,
  extendTheme,
  withDefaultColorScheme,
  useColorModeValue,
} from '@chakra-ui/react';
import components from './components';
import foundations from './foundations';
import layerStyles from './layers';
import styles from './styles';

const config: ThemeConfig = {
  useSystemColorMode: false,
};

const theme = {
  ...foundations,
  components,
  styles,
  config,
  layerStyles,
};

export default extendTheme(
  withDefaultColorScheme({
    colorScheme: 'now',
    components: ['Button', 'Menu'],
  }),
  theme,
);

