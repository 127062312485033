import {getColor, mode, PartsStyleFunction, SystemStyleFunction} from "@chakra-ui/theme-tools";
import { inputAnatomy as parts } from "@chakra-ui/anatomy"


const variantColorOutline: PartsStyleFunction<typeof parts>  = (props) => {
  const { theme } = props
  const { colorScheme: c } = props;

  return {
    field: {
      borderColor: mode(`${c}.500`, `${c}.200`)(props),
      _focus: {
        borderColor: mode(`${c}.500`, `${c}.200`)(props),
        boxShadow: `0 0 0 1px ${getColor(theme, mode(c + '.500', c + '.200')(props))}`,
      },
      _hover: {
        borderColor: mode(`${c}.600`, `${c}.400`)(props),
      }
    }
  }
};

const variants = {
  colorOutline: variantColorOutline,
};

export default {
  variants,
};
