import React from 'react';
import { __DEV__ } from '@chakra-ui/utils';
import { localStorageManager, StorageManager } from 'src/utils/storage-manager';

export type FontSizeValue = number;

export interface FontSizeProviderProps {
  value?: FontSizeValue;
  children?: React.ReactNode;
  fontSizeManager?: StorageManager;
}

interface FontSizeContextType {
  fontSize: FontSizeValue;
  setFontSize: (value: FontSizeValue) => void;
}

export const FontSizeContext = React.createContext({} as FontSizeContextType);
if (__DEV__) {
  FontSizeContext.displayName = 'FontSizeContext';
}

export const fontSizeKey = 'html-font-size';

export function FontSizeProvider({
  value,
  fontSizeManager = localStorageManager,
  children,
}: FontSizeProviderProps) {
  const [fontSize, rawSetFontSize] = React.useState<FontSizeValue>(16);

  const setFontSize = React.useCallback(
    (val: FontSizeValue) => {
      rawSetFontSize(val);
      fontSizeManager.set(fontSizeKey, val);
    },
    [rawSetFontSize],
  );

  React.useEffect(() => {
    const localFontSize = fontSizeManager.get(fontSizeKey);
    if (localFontSize) {
      rawSetFontSize(localFontSize);
    }
  }, [rawSetFontSize, fontSizeManager]);

  const context = {
    fontSize: value ?? fontSize,
    setFontSize,
  };

  React.useEffect(() => {
    document.documentElement.style.fontSize = `${context.fontSize}px`;
  }, [context]);

  return (
    <FontSizeContext.Provider value={context}>
      {children}
    </FontSizeContext.Provider>
  );
}

export const useFontSize = () => {
  const context = React.useContext(FontSizeContext);
  if (context === undefined) {
    throw new Error('useFontSize must be used within a FontSizeProvider');
  }

  return context;
};
