import {AutocompletePropGetters, BaseItem} from '@algolia/autocomplete-core';
import {CloseIcon} from '@chakra-ui/icons';
import { Flex, IconButton, Input, InputGroup, InputRightElement, Spinner, Text, useBreakpointValue, useColorModeValue, useOutsideClick } from '@chakra-ui/react';
import React, { RefObject, SyntheticEvent, useEffect, useRef, useState } from 'react';
import {MotionBox} from './MotionComps';

export interface SearchButtonProps {
  onFocus?: () => void;
  onBlur?: () => void;
  onSearch?: (searchText: string) => void;
  isSearching?: boolean;
  colorScheme?: string;
  inputRef:  RefObject<HTMLInputElement>;
  autoCompleteInputProps?: ReturnType<AutocompletePropGetters<BaseItem, SyntheticEvent, SyntheticEvent, SyntheticEvent>["getInputProps"]>
}

const SearchButton: React.FC<SearchButtonProps> = React.forwardRef<HTMLButtonElement, SearchButtonProps>(({
  onFocus,
  onSearch,
  onBlur,
  isSearching = false,
  colorScheme = 'now',
  autoCompleteInputProps,
  inputRef,
}, ref) => {
  let searchInput = useRef<HTMLInputElement>(null);
  if (inputRef) {
    searchInput = inputRef;
  }
  const [isSearchInputActive, setSearchInputActive] = useState(false);
  const searchInputGroup = useRef<HTMLInputElement>(null);

  useOutsideClick({
    ref: searchInputGroup,
    handler: () => {
      if (searchInput.current !== document.activeElement) setSearchInputActive(false);
    }
  })

  useEffect(() => {
    if (isSearchInputActive === true) {
      searchInput.current?.focus();
    } else {
      setSearchInputActive(false);
      autoCompleteInputProps && autoCompleteInputProps.onBlur();
      onBlur && onBlur();
    }
  }, [isSearchInputActive]);

  const colorProps: {
    colorScheme: string;
    color?: string;
  } = {
    colorScheme,
  };

  if (colorScheme === 'white') {
    colorProps.colorScheme = 'whiteAlpha';
    colorProps.color = 'white';
  }

  const position = useBreakpointValue({
    base: { position: 'absolute', right: 0 },
    sm: { position: 'absolute', right: 0 },
    md: { position: 'static' },
  });

  const inputBoxOpenStyle = useBreakpointValue({
    base: {
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem',
    },
    sm: {
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem',
    },
    md: {
      paddingLeft: undefined,
      paddingRight: undefined,
    },
  });
  

  return (
    <Flex justify="flex-end" align="center">
      {/* eslint-disable react/jsx-props-no-spreading */}
      <IconButton
        ref={ref}
        aria-label="Search"
        variant="unstyled"
        {...colorProps}
        icon={
          <Text as="span" className="iconfont icon-search" fontSize="2xl" />
        }
        onClick={() => {
          setSearchInputActive(true);
          onFocus && onFocus();
        }}
      />
      {/* eslint-enable react/jsx-props-no-spreading */}
      <MotionBox
        layout
        overflow="hidden"
        initial="closed"
        animate={isSearchInputActive ? 'open' : 'closed'}
        {...position}
        variants={{
          open: {
            width: '100%',
            ...inputBoxOpenStyle,
          },
          closed: {
            width: 0,
          },
        }}
        data-testid="inputBox"
      >
        <InputGroup ref={searchInputGroup}>
          <Input
            ref={searchInput}
            variant="outline"
            color="blackAlpha.700"
            bgColor="white"
            borderRadius="full"
            focusBorderColor={useColorModeValue(
              `${colorProps.colorScheme}.500`,
              `${colorProps.colorScheme}.200`,
            )}
            {...autoCompleteInputProps}
            onChange={event => {
              onSearch && onSearch(event.target.value);
              autoCompleteInputProps?.onChange(event);
            }}
            onBlur={() => {
              setSearchInputActive(false);
              autoCompleteInputProps?.onBlur();
            }}
            sx={{
              '&:focus': {
                boxShadow: 'unset',
              },
            }}
          />
          {isSearching ? (
            <InputRightElement
              children={<Spinner color="now.500" size="sm" />}
            />
          ) : searchInput.current?.value ? (
            <InputRightElement
              children={
                <IconButton
                  type="reset"
                  aria-label="Clear"
                  variant="unstyled"
                  color="now.500"
                  size="sm"
                  icon={<CloseIcon />}
                />
              }
            />
          ) : null}
        </InputGroup>
      </MotionBox>
    </Flex>
  );
});

export default SearchButton;
