import { ChakraTheme } from '@chakra-ui/react';

const foundations: Pick<
  ChakraTheme,
  'colors' | 'radii' | 'fonts' | 'fontSizes' | 'shadows'
> = {
  colors: {
    now: {
      50: '#fcf1e9',
      100: '#ffecdd',
      200: '#ffcab1',
      300: '#fea781',
      400: '#fb8550',
      500: '#f96332',
      600: '#df4906',
      700: '#af3803',
      800: '#7d2801',
      900: '#4c1600',
    },
    pureWhite: {
      500: 'white',
      400: 'rgba(255, 255, 255, 0.92)',
      300: 'rgba(255, 255, 255, 0.80)',
      200: 'rgba(255, 255, 255, 0.64)',
      100: 'rgba(255, 255, 255, 0.48)',
    },
    pureBlack: {
      500: 'black',
      400: 'rgba(0, 0, 0, 0.92)',
      300: 'rgba(0, 0, 0, 0.80)',
      200: 'rgba(0, 0, 0, 0.64)',
      100: 'rgba(0, 0, 0, 0.48)',
    },
    themed: {
      gray: '#888',
    },
  },
  radii: {
    primary: '0.1875rem',
  },
  shadows: {
    primary: '0 1px 8px rgb(0, 0, 0, 0.3)',
  },
  fonts: {
    body: 'Montserrat, Helvetica Neue, Arial, sans-serif',
  },
};

export default foundations;
