import {
  Avatar,
  Box,
  Button,
  Flex,
  Input,
  Link,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import React from 'react';
import {navigate} from 'gatsby';

export interface FooterProps {
  name?: string;
  avatar?: string;
  copyright?: string;
  socials?: Array<{
    name: string;
    url: string;
  }>;
}

const Footer: React.FC<FooterProps> = ({
  name,
  avatar,
  copyright,
  socials,
}: FooterProps) => {
  return (
    <Box
      as="footer"
      bg={useColorModeValue('gray.100', 'gray.900')}
      pt={16}
      pb={4}
    >
      <Flex minH={24} position="relative" direction={['column', 'row']}>
        <Box flex="1" position="relative">
          <Box m="auto" w="fit-content">
            <Avatar display="block" m="auto" w={16} h={16} src={avatar} />
            <Text mt={4}>{name}</Text>
          </Box>
        </Box>
        <Box flex="2">
          <Flex
            direction={['column', 'row']}
            my={[4, 0]}
            mx={16}
            alignItems="center"
          >
            <Button
              variant="outline"
              w={40}
              my={[3, 0]}
              ml={[0, 3]}
              leftIcon={
                <Text
                  as="span"
                  className="iconfont icon-arrow-right"
                  fontSize="xl"
                />
              }
              onClick={() => navigate('/rss.xml')}
            >
              获取RSS
            </Button>
          </Flex>
        </Box>
      </Flex>
      <Box textAlign="center" px={4}>
        <Box m="auto" mb={4}>
          {socials?.map(social => (
            <Link
              key={social.name}
              href={social.url}
              colorScheme="gray"
              variant="icon"
            >
              <Text
                as="span"
                mx={4}
                className={`iconfont icon-${social.name}`}
                fontSize="xl"
              />
            </Link>
          ))}
        </Box>
        {copyright}
      </Box>
    </Box>
  );
};

export default Footer;
