import { mode } from '@chakra-ui/theme-tools';

function baseStyle(props: Record<string, any>) {
  const { colorScheme: c } = props;
  if (c) {
    return {
      color: mode(`${c}.500`, `${c}.200`)(props),
    };
  }

  return {};
}

function variantNow(props: Record<string, any>) {
  const { colorScheme: c, color } = props;

  return {
    position: 'relative',
    _hover: {
      textDecoration: 'none',
      _after: {
        transform: 'scale(1)',
      },
    },
    _after: {
      content: '""',
      width: '100%',
      height: '1px',
      position: 'absolute',
      left: 0,
      bottom: 0,
      backgroundColor: color ? color : mode(`${c}.500`, `${c}.200`)(props),
      transform: 'scale(0)',
      transition: 'all .5s',
      mb: -1,
    },
  };
}

function variantIcon(props: Record<string, any>) {
  const { colorScheme: c } = props;

  return {
    _hover: {
      textDecoration: 'none',
      color: `${c}.400`,
    },
  };
}

function variantPlain() {
  return {
    _hover: {
      textDecoration: 'none',
    },
  };
}

const variants = {
  now: variantNow,
  icon: variantIcon,
  plain: variantPlain,
};

export default {
  baseStyle,
  variants,
};
